import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { SET_WARNING_DIALOG } from './ProjectSelect/reducer/slice';
import { Link } from 'react-router-dom';

export default function SelectProjectWarningDialog() {
  const open = useSelector((state: RootState) => state.project.showWarningDialog);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(SET_WARNING_DIALOG(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Seçili Projeniz Bulunmamaktadır</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Envanter oluşturabilmek için proje ekleyiniz. veya var olan projenizi seçiniz.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            width: 'max-content',
            padding: '10px 20px',
            background: '#F5F5F5',
            color: '#000000',
            border: 0,
            '&:hover': {
              background: '#F5F5F5'
            }
          }}
        >
          Kapat
        </Button>
        <Link to="/company/project/add">
          <Button onClick={handleClose}>Proje Ekle</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from 'react-use-cookie';

export enum AuthStatus {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT'
}

export interface User {
  id: string;
  name: string;
  email: string;
  token: {
    accessToken: {
      jwt: string;
      expiresIn: number | string;
    };
    refreshToken: {
      jwt: string;
      expiresIn: number | string;
    };
  };
}

interface AuthState {
  AUTH_STATUS: AuthStatus;
  USER: User | null;
  USER_COOKIE: string | null;
  COMPANY_COUNTRY_LOCATOIN: [string, string] | null;
}

const initialState = {
  AUTH_STATUS: AuthStatus.LOADING,
  USER: null,
  USER_COOKIE: getCookie('user'),
  COMPANY_COUNTRY_LOCATOIN: localStorage.getItem('companyCountry')
    ? JSON.parse(localStorage.getItem('companyCountry') as string)
    : null
} satisfies AuthState as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    LOGGED_IN: (state, action) => {
      state.AUTH_STATUS = AuthStatus.LOGGED_IN;
      state.USER = action.payload;
      setCookie('user', JSON.stringify(action.payload));
    },
    LOGGED_OUT: (state) => {
      state.AUTH_STATUS = AuthStatus.LOGGED_OUT;
      state.USER = null;
      state.USER_COOKIE = '';
      setCookie('user', '');
      localStorage.removeItem('isTokenSent');
      localStorage.removeItem('selectedProject');
      localStorage.removeItem('selectedProjectCountry');
    },
    SET_USER_COOKIE: (state, action) => {
      setCookie('user', JSON.stringify(action.payload));
      state.USER_COOKIE = JSON.stringify(action.payload);
    },
    SET_COMPANY_COUNTRY_LOCATOIN: (state, action) => {
      state.COMPANY_COUNTRY_LOCATOIN = [action.payload.latitude, action.payload.longitude];
      localStorage.setItem('companyCountry', JSON.stringify([action.payload.latitude, action.payload.longitude]));
    }
  }
});

export const { LOGGED_IN, LOGGED_OUT, SET_USER_COOKIE, SET_COMPANY_COUNTRY_LOCATOIN } = authSlice.actions;
export default authSlice.reducer;

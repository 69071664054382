import { Grid, IconButton, Stack, Typography, Button, TextField, InputAdornment } from '@mui/material';
import { default as PersonIcon } from '../../../assets/dashboard/main/person_icon.svg';
import { default as PersonYellowIcon } from '../../../assets/dashboard/main/person_yellow_icon.svg';
import { default as LeftArrowIcon } from '../../../assets/dashboard/main/left-arrow-icon-v2.svg';
import { default as PhoneIcon } from '../../../assets/dashboard/main/phone_yellow_icon_v2.svg';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../../../store/api/auth/api';
import { UpdateUserProfileRequestDto } from '../../../store/api/auth/dto';
import { useState } from 'react';
import { notifyError, notifySuccess } from '../../../components/notification/notify';
import { useDispatch } from 'react-redux';
import { reset } from './reducer/slice';
import InputPhone from '@/components/InputPhone';
import { useTranslation } from 'react-i18next';

const ProfileEdit = () => {
  const dispatch = useDispatch();

  const { data: userProfile } = useGetUserProfileQuery();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const [name, setName] = useState<string>(userProfile?.name as string);
  const [phone, setPhone] = useState<string>(userProfile?.phone as string);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const requestData: UpdateUserProfileRequestDto = {
      name,
      phone
    };

    try {
      const response = await updateUserProfile(requestData).unwrap();
      // Başarılı login işlemi sonrasında yapılacaklar
      //localStorage.setItem('token', response.auth.token);
      notifySuccess({ message: t('profileUpdated'), title: t('success') });
      dispatch(reset());
      // navigate("/companyInfo")
    } catch (error) {
      // Hata durumunda yapılacaklar
      notifyError({ message: t('updateError'), title: t('oneError') });
    }
  };

  return (
    <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
      <Grid xs={12} item height="100%">
        <Stack direction="row" justifyContent="space-between" marginBottom="4px" alignItems="center">
          <Stack direction="row" spacing="13px" alignItems="center">
            <Typography color={'#002E6D'} variant="h6">
              {t('editProfile')}
            </Typography>{' '}
          </Stack>
          <IconButton
            disableRipple
            onClick={() => {
              dispatch(reset());
            }}
          >
            <img src={LeftArrowIcon} alt="LeftArrowIcon" />
            <Typography sx={{ opacity: '60%', fontWeight: 400, ml: 2 }} variant="h6">
              {t('goBack')}
            </Typography>{' '}
          </IconButton>
        </Stack>

        <Stack direction="column" justifyContent="center" pt="52px">
          <Grid xs={12} pb="20px">
            <Typography sx={{ opacity: '60%', mb: 1, fontSize: 16 }}>{t('fullName')}</Typography>
            <TextField
              id="outlined-basic"
              size="medium"
              fullWidth
              variant="standard"
              value={name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={PersonYellowIcon} alt="PersonYellowIcon" height="24" />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid xs={12} pb="50px" mt="16px">
            <Typography sx={{ opacity: '60%', mb: 1, fontSize: 16 }}>{t('phoneNumber')}</Typography>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: '1px solid #002E6D'
              }}
            >
              <img src={PhoneIcon} alt="PhoneIcon" height="24" />
              <InputPhone
                inputProps={{
                  required: true
                }}
                inputStyle={{
                  border: 0,
                  borderRadius: 0,
                  borderColor: 'transparent',
                  width: '100%'
                }}
                searchStyle={{
                  border: 0
                }}
                containerStyle={{
                  border: 0
                }}
                buttonStyle={{
                  border: 0,
                  borderColor: 'transparent',
                  borderRadius: 0,
                  backgroundColor: 'transparent'
                }}
                dropdownStyle={{
                  border: 0
                }}
                value={phone}
                onChange={(value) => {
                  setPhone(value);
                }}
              />
            </Stack>
          </Grid>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Grid xs={4}>
            <Button
              onClick={handleSubmit}
              sx={{
                fontWeight: 400,
                fontSize: 15
              }}
            >
              {t('save')}
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProfileEdit;

import { generalApiService } from '../generalApiService';
import {
  AddressRequest,
  AddressResponse,
  CityRequest,
  CityResponse,
  CoordinatesRequest,
  CoordinatesResponse,
  CountryResponse,
  DistrictRequest,
  DistrictResponse,
  FileUploadProps,
  FileUploadResponse,
  SuggestRequest,
  SuggestResponse,
  TaxonomyTruckTypesResponse,
  TaxtOfficeRequest,
  TaxtOfficeResponse,
  ProductTypeResponse,
  PaletteTypeRequest,
  PaletteTypeResponse,
  NotificationResponse,
  CountryRequest,
  TaxonomyTrailersResponse,
  TaxonomyBrandsResponse
} from './dto';

const authApi = generalApiService.injectEndpoints({
  endpoints: (build) => ({
    systemStatus: build.query<void, void>({
      query: () => ({
        url: `/healthcheck`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    contryGet: build.query<CountryResponse, CountryRequest>({
      query: ({ take, skip }) => ({
        url: `/localization/country?take=${take}&skip=${skip}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    cityGet: build.query<CityResponse, CityRequest>({
      query: ({ country, take, skip }) => ({
        url: `/localization/country/${country}/cities?take=20&skip=0`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    districtGet: build.query<DistrictResponse, DistrictRequest>({
      query: ({ countryId, cityId, take, skip }) => ({
        url: `/localization/country/TR/cities/${cityId}/districts?take=20&skip=0`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    taxOfficeGet: build.query<TaxtOfficeResponse, TaxtOfficeRequest>({
      query: ({ city, country }) => ({
        url: `/localization/country/TR/city/${city}/tax-office`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    geoSuggest: build.query<SuggestResponse, SuggestRequest>({
      query: ({ suggest }) => ({
        url: `/map/suggest?address=${suggest}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    geoAddress: build.query<AddressResponse, AddressRequest>({
      query: ({ address }) => ({
        url: `/map/geocode/address?address=${address}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    geoCoordinates: build.query<CoordinatesResponse, CoordinatesRequest>({
      query: ({ lat, lng }) => ({
        url: `/map/geocode/coordinates?lat=${lat}&lng=${lng}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    taxonomyTruckTypes: build.query<TaxonomyTruckTypesResponse[], { totalWeight?: number | null }>({
      query: ({ totalWeight }) => ({
        url: `/taxonomy/truck-types${totalWeight ? `?totalWeight=${totalWeight}` : ''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),

    //Bura kullanulmıyor olabilir bakıcam
    /*     taxonomyTrailerAndBrands: build.query<TaxonomyTruckTrailersAndBrands, TaxonomyTruckTypeRequest>({
      query: ({ truckTypeId }) => ({
        url: `/taxonomy/truck-types/${truckTypeId}/trailers-and-brands`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }), */
    getTrailerTypes: build.query<TaxonomyTrailersResponse, { truckTypeId: string }>({
      query: ({ truckTypeId }) => ({
        url: `/taxonomy/trailers?truckTypeId=${truckTypeId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getBrands: build.query<TaxonomyBrandsResponse, void>({
      query: () => ({
        url: `/taxonomy/brands`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    storagePresignedUrl: build.mutation<FileUploadResponse, FileUploadProps>({
      query: ({ name }) => ({
        url: `/storage/presigned-url`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          fileName: name
        }
      })
    }),
    productTypeGet: build.query<ProductTypeResponse, void>({
      query: (body) => ({
        url: `/taxonomy/product-type`,
        method: 'GET',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    palletteTypeGet: build.query<PaletteTypeResponse, PaletteTypeRequest>({
      query: ({ type }) => ({
        url: `/taxonomy/package-type?type=${type}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    postDeviceToken: build.mutation<void, string>({
      query: (token) => ({
        url: `/device`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          token,
          type: 'browser'
        }
      })
    }),
    postNotification: build.mutation<void, void>({
      query: () => ({
        url: `/notification`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    notifyGet: build.query<NotificationResponse, { take: number; skip: number }>({
      query: ({ take, skip }) => ({
        url: `/notification?take=${take}&skip=${skip}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  })
});

export const {
  useSystemStatusQuery,
  useContryGetQuery,
  useCityGetQuery,
  useLazyCityGetQuery,
  useTaxOfficeGetQuery,
  useGeoSuggestQuery,
  useLazyGeoAddressQuery,
  useLazyGeoCoordinatesQuery,
  useDistrictGetQuery,
  useTaxonomyTruckTypesQuery,
  useLazyGetTrailerTypesQuery,
  useLazyGetBrandsQuery,
  useLazyTaxonomyTruckTypesQuery,
  useStoragePresignedUrlMutation,
  useProductTypeGetQuery,
  useLazyPalletteTypeGetQuery,
  usePostDeviceTokenMutation,
  useNotifyGetQuery,
  usePostNotificationMutation
} = authApi;
